/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== 'undefined') {
    if (location.pathname !== prevLocation?.pathname) {
      // Kill all existing ScrollTriggers
      if (window.ScrollTrigger) {
        window.ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      }

      // Wait for content to be ready before refreshing
      setTimeout(() => {
        if (window.ScrollTrigger) {
          window.ScrollTrigger.refresh(true);
        }
      }, 500);
    }
  }
};